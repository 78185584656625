import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import News from "../components/news"
import { FaArrowRight } from "react-icons/fa"

import "../scss/companies.scss"
import "../scss/uteckie.scss"

import HeaderLogoImg from "../assets/img/compsol/uteckie_1.svg"
import Icon1 from "../assets/img/uteckie/Icon1.png"
import Icon2 from "../assets/img/uteckie/Icon2.png"
import Icon3 from "../assets/img/uteckie/Icon3.png"
import Icon4 from "../assets/img/uteckie/Icon4.png"
import Icon5 from "../assets/img/uteckie/Icon5.png"
import Icon6 from "../assets/img/uteckie/Icon6.png"
import SingleForm from "../components/singleForm"

const UteckiePage = () => {
  return (
    <div>
      <SEO title="uTeckie" keywords={[`youpal`, `youpal group`, `uTeckie`]} />
      <div className="youMain uteckieMain">
        <section className="container sectionHeader">
          <div className="col">
            <HeaderLogoImg className="headerLogo" />
            <div className="internalMainHeader bugP">
              <h2 className="internalNarrowCol">
                Stay on top of the tech news wave
              </h2>
            </div>
            <p className="internalNarrowCol">
              Uteckie news portal introduces you to the most recent news in the
              tech industries. With us, you can discover top tendencies,
              challanges and development of different tech-related fields.
            </p>
          </div>
        </section>

        <div className="mainBody">
          <section className="container">
            <div className="col">
              <span className="internalNarrowCol internalSmallHeader">
                Features
              </span>
              <h3 className="internalNarrowCol featuresTitle">
                Technology is best, when it brings people together, Uteckie
                brings together techurios people
              </h3>
            </div>
          </section>

          <section className="container exploreSection">
            <div className="row">
              <div className="exploreFeature">
                <div className="row">
                  <div>
                    <img src={Icon1} />
                  </div>
                  <div className="col sideContent">
                    <h4>News</h4>
                    <p>
                      You can always follow on the most interesting news and
                      challanges in tech industry with Uteckie.
                    </p>
                  </div>
                </div>
              </div>
              <div className="exploreFeature">
                <div className="row">
                  <div>
                    <img src={Icon2} />
                  </div>
                  <div className="col sideContent">
                    <h4>Gadgets</h4>
                    <p>
                      Stay up to date with the most modern and smart gadgets in
                      the global market.
                    </p>
                  </div>
                </div>
              </div>
              <div className="exploreFeature">
                <div className="row">
                  <div>
                    <img src={Icon3} />
                  </div>
                  <div className="col sideContent">
                    <h4>Mobile</h4>
                    <p>
                      Get access to global news and important topics via simple
                      mobile application
                    </p>
                  </div>
                </div>
              </div>
              <div className="exploreFeature">
                <div className="row">
                  <div>
                    <img src={Icon4} />
                  </div>
                  <div className="col sideContent">
                    <h4>Podcasts</h4>
                    <p>
                      Check out our social media and listen to our podcast where
                      we invite interesting guests to discuss tech related
                      topics and more!
                    </p>
                  </div>
                </div>
              </div>
              <div className="exploreFeature">
                <div className="row">
                  <div>
                    <img src={Icon5} />
                  </div>
                  <div className="col sideContent">
                    <h4>Gaming</h4>
                    <p>
                      The gaming sector is one of the biggest industries and
                      undergoes some major changes daily
                    </p>
                  </div>
                </div>
              </div>
              <div className="exploreFeature">
                <div className="row">
                  <div>
                    <img src={Icon6} />
                  </div>
                  <div className="col sideContent">
                    <h4>Events</h4>
                    <p>
                      The gaming sector is one of the biggest industries and
                      undergoes some major changes daily
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container internalSection buttonSection">
            <div className="buttonArea">
              <a href="https://uteckie.com/" target="__blank">
                <button type="button" className="btn btn-primary px-4">
                  <span>Find out more</span>
                  <FaArrowRight style={{ fill: "#183B56" }} />
                </button>
              </a>
            </div>
          </section>

          <section id="News" className="container latestNews">
            <News />
          </section>

          <section className="container contactSection">
            <div className="row contactBox">
              <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=23" selector="contact-us-mautic-company-form" />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default UteckiePage
